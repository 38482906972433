import { DefaultPage, DefaultPageProps } from '~/components/page'
import { pageQuery, pageTypeFilter } from '~/queries'
import type { GetStaticPaths, GetStaticProps, Page } from '~/types'
import { REVALIDATE_TIME, getClient, getPageSlug } from '~/utils'

export const getStaticProps: GetStaticProps<DefaultPageProps> = async ({
	params = {},
	preview = null,
	previewData = null
}) => {
	const pageFilter = /* groq */ `((category->slug.current == $category && slug.current == "/") || slug.current == $category && category->_type != "product")`
	const page = await getClient(preview, previewData).fetch<Page[]>(pageQuery(pageFilter), params)
	return page
		? { props: { pageFilter, page, preview, params, previewData }, revalidate: REVALIDATE_TIME } //
		: { props: { params }, notFound: true, revalidate: REVALIDATE_TIME }
}

export const getStaticPaths: GetStaticPaths = async (_ctx) => {
	const pathsQuery = /* groq */ `
		*[${pageTypeFilter} && ((slug != null && category == null) || (category != null && slug == "/"))] {
			slug,
			category->
		}
	`

	const pages: Page[] = await getClient().fetch(pathsQuery)
	const paths = pages
		.map((page) => getPageSlug(page))
		.filter((slug) => slug.path !== '/' && !slug.path.startsWith('products')) // Handled by index
		.map((slug) => ({ params: { category: slug.category ?? slug.slug } }))
	return { paths, fallback: true }
}

const Page: React.FC<DefaultPageProps> = (props) => <DefaultPage {...props} />
export default Page
